<style lang="scss" scoped>
@import "./management.modal";

.modal-mask {
  position: fixed;
  z-index: 3;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  transition: opacity 0.3s ease;
}

.modal-content-container {
  background-color: white;
  min-width: 110vw;
  border-radius: 12px;
  padding-bottom: 5vh;
}

.user-management__header-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px;
  border-radius: 10px 10px 0px 0px;
  background-color: #ff7110;
}

.user-management__header-title {
  color: #ffffff;
  font-weight: 500;
  font-size: 32px;
}

.closebtn-icon {
  color: #ffffff;
  font-size: 30px;
  cursor: pointer;
}

.management-modal__actions-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 2em;
}

.management-modal__tab-container {
  display: flex;
  gap: 5vw;
  margin: 2em;
  border-bottom: 1px solid lightgray;
  padding-bottom: 2vh;
}

.modal-table-content {
  margin: 1em 2em;
}

.page-table-header {
  background: #FFFEFC;
  border: 0.5px solid #E5E5E5;
  font-weight: 400;
  color: #605F5F;
  display: flex;
  padding: 1em 0em;
}

.page-table-header-text {
  padding-left: 10px;
  font-weight: 600;
  font-size: 1.19em;
  color: #505050;
}

.page-table-row {
  display: flex;
  align-items: center;
  font-weight: 300;
  font-size: 1em;
  color: #605F5F;
}

.manage-modal-tab-button {
  padding: 1vh 2vw;
  border-radius: 8px;
  cursor: pointer;
  font-weight: 400;
  font-size: 18px;
  letter-spacing: 0.15px;
  background-color: white;
  color: #202020;
  font-weight: 500;
  display: flex;
  align-items: center;
  gap: 1vw;
}

.manage-modal-tab-button-activated {
  border-radius: 8px 8px 8px 8px;
  background-color: #fdf0e9;
  color: #202020;
  cursor: default;
}
.edituser-tab-selector-divison{
  display: flex;
  gap: 2vw;
}

.page-table-desc-column {
  padding: 0.5em 0.7em;
}

.page-table-line-special {
  background: #F7F7F7;
}

.name-width {
  width: 14%;
}

.login-width {
  width: 14%;
  word-break: break-all;
}

.phone-width {
  width: 14%;
}

.store-width {
  width: 20%;
}

.password-width {
  width: 10%;
}

.created-at-width {
  width: 14%;
}

.actions-width {
  width: 12%;
}

.actions-icon-wrapper {
  display: flex;
  gap: 1vw;
}

.edit-icon {
  color: #505050;
  cursor: pointer;
}

.remove-icon {
  color: #CB4243;
  cursor: pointer;
}

.user-icon-orange {
  color: #FF7110;
  cursor: pointer;
}

.user-icon-blue {
  color: #0677C9;
  cursor: pointer;
}
.edituser-tab-selector-icon{
  cursor: pointer;
  min-width: 17vw;
  font-size: 1.1em;
  color: #202020;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10%;
  padding: 2vh 0;
  border-radius: 8px;
  font-weight: 600;
}
.edituser-tab-selector-icon:hover{
  background-color: #fdf0e9;
}
.edituser-tab-selector-icon-active{
  background-color: #fdf0e9;
  cursor: default;
}

.input-container {
  border: 1px solid #E5E5E5;
  box-sizing: border-box;
  border-radius: 8px;
  padding: 12px;
  margin-top: 5px;
  display: flex;
  width: 35vw;
}
.desactive-icon{
  color: #FF7110;
}
.input-title {
  font-weight: 400;
  font-size: 20px;
  line-height: 24px;
  letter-spacing: 0.15px;
  color: #898989;
}

.input-value {
  border: none;
  flex: 1;
  font-weight: 400;
  font-size: 14px;
  letter-spacing: 0.15px;
  color: #B3B3B3;
}

.input-value:focus {
  border: none;
  outline: none;
}

.management-modal-body {
  height: 85vh;
  overflow: auto;
}

.page-table-header-mobile {
  display: none;
}

@media only screen and (max-width: 750px) {
  .page-table-header {
    display: none;
  }

  .page-table-row {
    flex-direction: column;
    font-size: 2.5vw;
  }

  .name-width {
    width: 100%;
  }

  .login-width {
    width: 100%;
  }

  .phone-width {
    width: 100%;
  }

  .store-width {
    width: 100%;
  }

  .password-width {
    width: 100%;
  }

  .created-at-width {
    width: 100%;
  }

  .actions-width {
    width: 100%;
  }

  .page-table-header-mobile {
    display: flex;
  }

  .page-table-desc-column {
    display: flex;
    justify-content: space-between;
  }

  .page-table-header-mobile {
    font-size: 1.2em;
    font-weight: bold;
  }

  .modal-content-container {
    width: 115vw;
  }

  .input-container {
    width: 45vw;
  }

  .user-management__header-title {
    font-size: 25px;
  }
}

@media only screen and (max-width: 450px) {
  .page-table-row {
    font-size: 4vw;
  }

  .management-modal__actions-container {
    flex-direction: column;
    gap: 2vh;
  }

  .input-container {
    width: 90vw;
  }

  .button-containear {
    width: 43vw;
  }
}
</style>
<template>
  <div class="modal-mask">
    <div class="modal-content-container">
      <div class="user-management__header-container">
        <span class="user-management__header-title">
          Editar Usuários: {{ client.name }}
        </span>
        <span @click="closeDialog" class="material-icons closebtn-icon">
          cancel
        </span>
      </div>

      <div class="management-modal-body">
        <div class="management-modal__actions-container">
          <div v-if="VIEW == 'BUYER_LIST' && !buyer_id">
            <div class="input-title">Nome</div>
            <div class="input-container">
              <input type="text" v-model="filters.name" class="input-value" placeholder="Digite o nome do comprador">
            </div>
          </div>
          <div v-else-if="current_buyer.id && (VIEW == 'BUYER_FORM' || VIEW == 'BUYER_EXTERNAL_VR_FORM' || VIEW == 'BUYER_EXTERNAL_FORM')">
            <div class="edituser-tab-selector-divison">
                <div :class="getActiveTab('BUYER_FORM')" class="edituser-tab-selector-icon " @click=" edit(current_buyer) ">
                    <span class="material-icons-outlined desactive-icon">manage_accounts</span>
                    Editar Usuário
                </div>
                <div :class="getActiveTab('BUYER_EXTERNAL_VR_FORM')" class="edituser-tab-selector-icon" @click=" edit_external_vr(current_buyer) ">
                    <span class="material-icons-outlined desactive-icon">contact_mail</span>
                    Editar Usuário VF
                </div>
                <div :class="getActiveTab('BUYER_EXTERNAL_FORM')" class="edituser-tab-selector-icon" @click=" edit_external(current_buyer) ">
                  <span class="material-icons-outlined desactive-icon">people_alt</span>
                  Editar Usuário Externo
              </div>
            </div>
          </div>
          <div v-else></div>
          <div style="display: flex;">
            <Button style="margin-left: 10px;" v-if="VIEW == 'BUYER_LIST'" :iconName="'arrow_back'" title="Voltar"
              :text="'Voltar'" :action="() => { closeDialog(); current_seller = null; VIEW = 'BUYER_LIST' }" />
            <Button style="margin-left: 10px;" v-if="VIEW != 'BUYER_LIST' && !buyer_id" :iconName="'arrow_back'"
              title="Voltar" :text="'Voltar'" :action="() => { current_seller = null; VIEW = 'BUYER_LIST' }" />
            <Button style="margin-left: 10px;" v-if="VIEW == 'BUYER_FORM' || VIEW == 'BUYER_EXTERNAL_VR_FORM'"
              title="Salvar" :text="'Salvar'" :action="save" />
            <Button style="margin-left: 10px;" v-if="VIEW == 'BUYER_FORM' && VIEW == 'BUYER_EXTERNAL_FORM'" title="Salvar"
              :text="'Salvar'" :action="save_external" />
            <Button style="margin-left: 10px;"
              v-if="VIEW != 'BUYER_FORM' && VIEW != 'BUYER_EXTERNAL_FORM' && VIEW != 'BUYER_EXTERNAL_VR_FORM'"
              :iconName="'add_circle'" title="Adicionar Comprador" :text="'Adicionar Comprador'" :action="add" />
          </div>
        </div>
        <div class="management-modal__tab-container" v-if="VIEW == 'BUYER_LIST'">
          <div class="manage-modal-tab-button" :class="getActiveButton('users')" @click="tab = 'users'">
            <span class="material-icons" style="color: #FF7110">person</span>
            Usuarios da cotação
          </div>
          <div class="manage-modal-tab-button" :class="getActiveButton('directUsers')" @click="tab = 'directUsers'">
            <span class="material-icons" style="color: #FF7110">currency_exchange</span>
            Usuarios da venda direta
          </div>
        </div>
        <div v-if="tab == 'users'">
          <div style="margin: 0em;">
            <UserForm ref="seller-form" @close="current_buyer = null; VIEW = 'BUYER_LIST'"
              v-if="current_buyer && VIEW == 'BUYER_FORM'" :profiles="profiles" :secondary_profiles="secondary_profiles"
              :stores="stores" @reload=" () => !buyer_id ? load() : $emit('reload') " :buyer="current_buyer" />

            <UserExternalVRForm ref="seller-form" @close=" current_buyer = null; VIEW = 'BUYER_LIST' "
              v-if=" current_buyer && VIEW == 'BUYER_EXTERNAL_VR_FORM' " :profiles=" profiles "
              :secondary_profiles=" secondary_profiles " :stores=" stores "
              @reload=" () => !buyer_id ? load() : $emit('reload') " :buyer=" current_buyer " />

            <UserExternalForm ref="seller-form" @close=" current_buyer = null; VIEW = 'BUYER_LIST' "
              v-if=" current_buyer && VIEW == 'BUYER_EXTERNAL_FORM' " :profiles=" profiles "
              :secondary_profiles=" secondary_profiles " :stores=" stores "
              @reload=" () => !buyer_id ? load() : $emit('reload') " :buyer=" current_buyer " />
              
          </div>
          <div v-show=" VIEW == 'BUYER_LIST' && !buyer_id ">
            <div class="modal-table-content">
              <div class="page-table-header">
                <div class="page-table-header-text name-width">Nome</div>
                <div class="page-table-header-text login-width">Login</div>
                <div class="page-table-header-text phone-width">Telefone</div>
                <div class="page-table-header-text store-width">Loja</div>
                <div class="page-table-header-text password-width">Senha</div>
                <div class="page-table-header-text created-at-width">Criado em</div>
                <div class="page-table-header-text actions-width">Ações</div>
              </div>
              <div v-for="( user, idx ) in  users " :key=" user.id " :class=" getSpecialBackground(idx) " v-show=" user.visible ">
                <div class="page-table-row">
                  <div class="page-table-desc-column name-width">
                    <div class="page-table-header-mobile">Nome</div>{{ user.name }}
                  </div>
                  <div class="page-table-desc-column login-width">
                    <div class="page-table-header-mobile">Login</div>{{ user.login }}
                  </div>
                  <div class="page-table-desc-column phone-width">
                    <div class="page-table-header-mobile">Telefone</div>
                    <cc-whatsapp :phone=" user.phone " />
                  </div>
                  <div class="page-table-desc-column store-width">
                    <div class="page-table-header-mobile">Loja</div>{{ user.store ? user.store.name : '-' }}
                  </div>
                  <div class="page-table-desc-column password-width">
                    <div class="page-table-header-mobile">Senha</div>{{ user.decrypted_password }}
                  </div>
                  <div class="page-table-desc-column created-at-width">
                    <div class="page-table-header-mobile">Criado em</div>{{ user.created_at | formatDate }}
                  </div>
                  <div class="page-table-desc-column actions-width">
                    <div class="page-table-header-mobile">Ações</div>
                    <div class="actions-icon-wrapper">
                      <span class="material-icons-outlined edit-icon" title="Editar Vendedor" @click=" edit(user) ">
                        edit
                      </span>
                      <span class="material-icons-outlined remove-icon" title="Remover vendedor"
                        @click=" remove_buyer(user) ">
                        delete
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div v-else>
          <DirectSaleVue :closeModal=" () => openCreateNewUser = false " :openModal=" openCreateNewUser "
            :searchText=" filters.name " :client=" client " />
        </div>
      </div>




    </div>
    <cc-loader-full v-if="loading"/>
  </div>
  <!-- <cc-modal :modal="modal" >
        <div slot="header-actions">
            <div class="row">
                <div class="col actions">
                    <cc-button :content="'Voltar'"
                        :icon="'fas fa-chevron-left'"
                        @click="current_seller = null;VIEW = 'BUYER_LIST'"
                        :classes="'fill default fixed'"
                        v-if="VIEW != 'BUYER_LIST' && !buyer_id" />

                    <cc-button :content="'Fechar'"
                        @click="close();current_seller = null;VIEW = 'BUYER_LIST'"
                        :classes="'fill danger-outline-no-hover fixed'"
                        v-if="VIEW == 'BUYER_LIST'" />

                    <cc-button
                        v-if="VIEW == 'BUYER_FORM' || VIEW == 'BUYER_EXTERNAL_VR_FORM'"
                        :nolink="true"
                        :content="'Salvar'"
                        @click="save"
                        :classes="'thin ml-2 theme-3 fixed'" />

                    <cc-button
                        v-if="VIEW == 'BUYER_FORM' || VIEW == 'BUYER_EXTERNAL_FORM'"
                        :nolink="true"
                        :content="'Salvar'"
                        @click="save_external"
                        :classes="'thin ml-2 theme-3 fixed'" />
                    <cc-button
                        v-if="VIEW != 'BUYER_FORM' && VIEW != 'BUYER_EXTERNAL_FORM' && VIEW != 'BUYER_EXTERNAL_VR_FORM'"
                        :nolink="true"
                        :content="'Adicionar'"
                        @click="add"
                        :classes="'thin ml-2 theme-3 fixed'" />
                </div>
            </div>
        </div>
        <div slot="body" class="container-fluid pt-0 sellers" id="sellers-content">
            <div class="row">
                <UserForm
                    ref="seller-form"
                    @close="current_buyer = null;VIEW = 'BUYER_LIST'"
                    v-show="current_buyer && VIEW == 'BUYER_FORM'"
                    :profiles="profiles"
                    :secondary_profiles="secondary_profiles"
                    :stores="stores"
                    @reload="() => !buyer_id ? load() : $emit('reload') "
                    :buyer="current_buyer" />

                <UserExternalVRForm
                    ref="seller-form"
                    @close="current_buyer = null;VIEW = 'BUYER_LIST'"
                    v-show="current_buyer && VIEW == 'BUYER_EXTERNAL_VR_FORM'"
                    :profiles="profiles"
                    :secondary_profiles="secondary_profiles"
                    :stores="stores"
                    @reload="() => !buyer_id ? load() : $emit('reload') "
                    :buyer="current_buyer"/>

                <UserExternalForm
                    ref="seller-form"
                    @close="current_buyer = null;VIEW = 'BUYER_LIST'"
                    v-show="current_buyer && VIEW == 'BUYER_EXTERNAL_FORM'"
                    :profiles="profiles"
                    :secondary_profiles="secondary_profiles"
                    :stores="stores"
                    @reload="() => !buyer_id ? load() : $emit('reload') "
                    :buyer="current_buyer" />
                <div class="col-12 pb-4 p-0 d-flex align-items-center" v-if="VIEW == 'BUYER_LIST' && !buyer_id">
                    <div class="col col-4">
                        <cc-search v-model="filters.name" :placeholder="'Filtrar por nome'" :noicon="true" />
                    </div>
                </div>
                <div class="col" id="sellers" v-show="VIEW == 'BUYER_LIST' && !buyer_id">
                    <div class="list-group">
                        <cc-loader v-show="loading"/>
                        <no-data-available v-if="!loading && users && users.length == 0" :text="'Sem usuários cadastrados'" />
                        <table class="table" v-show="!loading && users && users.length > 0">
                            <thead>
                                <tr>
                                    <th class="pl-2">Nome</th>
                                    <th>Login</th>
                                    <th>Telefone</th>
                                    <th>Loja</th>
                                    <th>Senha</th>
                                    <th>Criado em</th>
                                    <th></th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr v-for="user in users" v-bind:key="user.id" v-show="user.visible">
                                    <td>{{ user.name }}</td>
                                    <td>{{ user.login }}</td>
                                    <td><cc-whatsapp :phone="user.phone" /></td>
                                    <td>{{ user.store ? user.store.name : '-' }}</td>
                                    <td>{{ user.decrypted_password }}</td>
                                    <td>{{ user.created_at | formatDate }}</td>
                                    <td>
                                        <div class="actions">
                                            <i class="fas fa-edit" aria-hidden="true" title="Editar Vendedor" @click="edit(user)"></i>
                                            <i title="Remover vendedor"
                                                class="fa fa-trash"
                                                @click="remove_buyer(user)"></i>
                                            <i class="fas fa-external-link-square-alt" aria-hidden="true" title="Editar Usuário VR" @click="edit_external_vr(user)"></i>
                                            <i class="fas fa-external-link-square-alt" aria-hidden="true" title="Editar Usuário Externo" @click="edit_external(user)"></i>
                                        </div>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>

                </div>
            </div>
        </div>
    </cc-modal> -->
</template>

<script>

import StoreService from "@/services/v1/store.service";
import ClientService from "@/services/v3/client.service";
import BuyerService from "@/services/v3/clients/buyer.service";
import ErrorHandlerService from '@/services/ErrorHandlerService';
import SysConfService from '@/services/v3/management/sysconf.service';
import { loaderMixin } from '@/mixins/sweet-loader.mixin';
import { ValidationProvider, ValidationObserver } from 'vee-validate';
import UserForm from "./_user.form.vue"
import UserExternalForm from "./_user_external.form.vue"
import UserExternalVRForm from "./_user_external_vr.form.vue"
import Button from '@/components/ui/buttons/StandardButton.vue'
import StandardInput from '@/components/ui/inputs/StandardInput.vue'
import DirectSaleVue from "./DirectSale.vue";
import UserService from "@/services/v1/user.service";
import QuotationService from "../../../services/QuotationService";

export default {
  mixins: [loaderMixin],
  props: {
    client: {
      type: Object,
      required: true
    },
    buyer_id: { type: Number },
    closeDialog: {
      type: Function,
    }
  },
  components: {
    ValidationProvider,
    ValidationObserver,
    UserForm,
    UserExternalForm,
    UserExternalVRForm,
    Button,
    StandardInput,
    DirectSaleVue
  },
  data() {
    return {
      users: [],
      current_buyer: null,
      loading: false,
      message: null,
      profiles: [],
      tab: 'users',
      openCreateNewUser: false,
      stores: [],
      settings: {
        user_external: null,
        user_pass: null,
        user_domain: null
      },
      filters: {
        name: ''
      },
      VIEW: "BUYER_LIST",
      svc: new ClientService(),
      store_svc: new StoreService(),
      buyer_svc: new BuyerService(),
      sys_svc: new SysConfService(),
      userSvc: new UserService(),
      quotationService: new QuotationService(),
      page: 1,
      total_items: 0,
      modal: {
        title: `meus usuários`,
        subtitle: `Gerencie seus usuários`,
        icon_title: 'fas fa-users',
        cancel_text: 'Voltar',
        nobackbutton: true,
        style: {
          width: "90%",
          height: "fit-content"
        }
      },
      secondary_profiles: [
        { value: 0, key: 'Repositor' },
        { value: 2, key: 'Gerente' },
        { value: 1, key: 'Conferente' },
      ]
    };
  },
  watch: {
    filters: {
      handler: function () {
        this.page = 1
        let rx = new RegExp(this.filters.name, 'gi')
        this.users.forEach(u => u.visible = false)
        this.users
          .filter(u => !this.filters.name || u.name.match(rx))
          .forEach(u => u.visible = true)
      },
      deep: true
    }
  },
  methods: {
    getActiveTab (tab) {
      if (tab == this.VIEW) return 'edituser-tab-selector-icon-active'
    },
    getActiveButton(value) {
      if (value == this.tab) return 'manage-modal-tab-button-activated'
    },
    setNameValue(value) {
      this.filters.name = value
    },
    getSpecialBackground(idx) { if (idx % 2 != 0) return 'page-table-line-special' },
    edit(buyer) {
      this.VIEW = 'BUYER_FORM'
      this.current_buyer = { ...buyer, client: this.client }
      this.update_modal({ title: `${buyer.name}` })
    },
    edit_external_vr(buyer) {
      this.VIEW = 'BUYER_EXTERNAL_VR_FORM';
      this.load_modal(buyer);
    },
    edit_external(buyer) {
      this.load_user_external(buyer);
    },
    load_modal(buyer) {
      this.current_buyer = { ...buyer, client: this.client }
      this.update_modal({ title: `${buyer.name}` })
    },
    set_profile(profile) {
      this.$set(this.current_buyer, 'profile', profile)
      if (profile && profile.pfl_descricao == 'ADMINISTRADOR') {
        this.$set(this.current_buyer, 'store', null)
        this.current_buyer.store = null
      }
      this.$forceUpdate()
    },
    async set_store() {
      this.$set(this.current_buyer, 'store', this.current_buyer.store)
      this.$forceUpdate()
    },
    remove_buyer(buyer) {
      this.confirm_action({
        message: `Confirma remoção do comprador ${buyer.name}?`,
        callback: () => this.remove(buyer.id)
      })
    },
    remove(buyer_id) {
      return this.buyer_svc.remove(this.client.id, buyer_id).then(() => this.load_users())
    },
    update_modal(new_settings) {
      this.modal = { ...this.modal, ...new_settings }
    },
    add() {
      if (this.tab == 'users') {
        this.VIEW = 'BUYER_FORM'
        this.current_buyer = { client: this.client }
        this.update_modal({ title: `Novo Comprador` })
      } else {
        this.openCreateNewUser = true
      }
    },
    save() {
      this.present_loader("Salvando usuário...")
      this.message = null

      let callback = data => {
        this.dismiss_loader()
        const notification = {
          type: 'success',
          message: "Dados salvos com sucesso!"
        }
        this.$store.dispatch('notification/add', notification)
      }

      let callback_error = err => {
        this.dismiss_loader()
        let message = 'Ops! Houve um erro ao salvar o usuário, tente novamente mais tarde!'
        if (err.response && err.response.data && err.response.data.errors) {
          let errors = err.response.data.errors
          message = Object.values(errors).flat()[0]
        }
        const notification = {
          type: 'error',
          message: message
        }
        this.$store.dispatch('notification/add', notification)
      }

      this.userSvc.update2(this.client.id, this.current_buyer.id, this.current_buyer)
        .then(() => this.load_users())
        .then(callback, callback_error)
    },
    save_external() {
      console.log('test');
      this.present_loader("Salvando usuário...")
      this.message = null

      let callback = data => {
        this.dismiss_loader()
        const notification = {
          type: 'success',
          message: "Dados salvos com sucesso!"
        }
        this.$store.dispatch('notification/add', notification)
      }

      let callback_error = err => {
        this.dismiss_loader()
        let message = 'Ops! Houve um erro ao salvar o usuário, tente novamente mais tarde!'
        if (err.response && err.response.data && err.response.data.errors) {
          let errors = err.response.data.errors
          message = Object.values(errors).flat()[0]
        }
        const notification = {
          type: 'error',
          message: message
        }
        this.$store.dispatch('notification/add', notification)
      }

      this.sys_svc.update_user_external(this.current_buyer)
        .then(() => this.load_users())
        .then(callback, callback_error)
    },
    saveEdit() {
      // if (!this.editUser.usu_telefone) {
      //   const notification = {
      //     type: 'error',
      //     message: 'Por favor, insira um numero de telefone.'
      //   }
      //   this.$store.dispatch('notification/add', notification)
      //   return
      // }
      // this.editUser.profile = this.editUserProfile
      // this.message = null
      let callback = data => {
        const notification = {
          type: 'success',
          message: "Dados salvos com sucesso!"
        }
        this.$store.dispatch('notification/add', notification)
        this.cancel()
      }
      let callback_error = err => {
        let message = 'Ops! Houve um erro ao salvar o usuário, tente novamente mais tarde!'
        if (err.response && err.response.data && err.response.data.error) {
          message = err.response.data.error
        }
        const notification = {
          type: 'error',
          message: message
        }
        this.$store.dispatch('notification/add', notification)
      }
      if (this.editUser.usu_id) {
        this.editUser.usu_integracao = this.editExternalUserProfile == 'VAREJO FACIL' ? 'V' : 'P'
        if (this.editUserProfile && this.editUserProfile.pfl_descricao == 'ADMINISTRADOR') {
          this.editUser.store = {}
          this.editUser.secondary_profile = {}
        } else this.editUser.usu_comparativo = 1

        console.log(this.editUser);
        this.userSvc.update(this.client.cli_id, this.editUser.usu_id, this.editUser)
          .then(() => this.load_users())
          .then(callback, callback_error)

      } else {
        this.editUser.usu_login = this.editUser.usu_email
        this.userSvc.create(this.client.cli_id, this.editUser)
          .then(() => this.load_users())
          .then(callback, callback_error)
      }
    },
    close() {
      this.$emit("close");
    },
    load_users() {
      this.present_loader("Carregando usuários...")
      return this.svc.load_buyers(this.client.id, { name: this.filters.name }).then(response => response.data).then(data => {
        this.load_profiles().then(() => {
          this.users = data;
          this.users.forEach(u => u.visible = true)
        }).then(() => {
          this.load_stores().then(() => {
          })
          this.dismiss_loader()
        })
      }).catch(error => {
        ErrorHandlerService.handle(error, this.$store);
      });
    },
    load_profiles() {
      return this.svc.load_profiles(this.client.id).then(response => response.data).then(data => {
        this.profiles = data.data
      })
    },
    load_stores() {
      return this.svc.load_stores(this.client.id).then(response => response.data).then(data => {
        this.stores = data
      })
    },
    process_profiles() {
      this.users.forEach(u => u.profile = this.profiles.find(p => p.id == u.id))
      this.users.forEach(u => u.secondary_profile = this.secondary_profiles.find(p => u.usu_acesso_comp_loja == p.value))
      this.$forceUpdate()
    },
    process_stores() {
      this.users.forEach(u => u.store = this.stores.find(p => p.id == u.id))
      this.$forceUpdate()
    },
    load_user_external(buyer) {
      this.loading = true
      return this.sys_svc.load_user_external(buyer.id).then(response => response.data).then(data => {
        this.load_settings(buyer, data);
        this.loading = false
      })
    },
    load_settings(buyer, settings) {
      const find_user = settings.find(x => x.key == 'EXTERNAL_USER');
      const find_pass = settings.find(x => x.key == 'EXTERNAL_PASSWORD');
      const find_domain = settings.find(x => x.key == 'EXTERNAL_SUBDOMAIN');
      if (find_user) {
        this.settings.user_external = find_user.value;
      }
      if (find_user) {
        this.settings.user_pass = find_pass.value;
      }
      if (find_domain) {
        this.settings.user_domain = find_domain.value;
      }
      this.VIEW = 'BUYER_EXTERNAL_FORM';
      this.load_modal(buyer);
    }
  },
  mounted() {
    this.load_users()
  }
};
</script>
