<template>
    <div class="pay-modal-mask">
        <div class="pay-modal-container">
            <div class="pay-header-container">
                <h1 class="pay-header-title">Boletos</h1>
                <span @click="closeDialog" class="material-icons closebtn-icon">cancel</span>
            </div>
            <div class="pay-body">
                <div class="page-table-header" v-if="bills.length > 0 && !isLoading">
                    <div class="page-table-header-text id-width">ID</div>
                    <div class="page-table-header-text date-width">Vencimento</div>
                    <div class="page-table-header-text value-width">Valor do boleto</div>
                    <div class="page-table-header-text link-width">Link</div>
                    <div class="page-table-header-text input-width">Valor Pago</div>
                    <div class="action-width"></div>
                </div>
                <div class="pay-table-row" v-for="bill in bills" :key="bill.id">
                    <h4 class="pay-table-text id-width">#{{bill.chargeId}}</h4>
                    <h4 class="pay-table-text date-width">{{bill.dueDate.split('-').reverse().join('/')}}</h4>
                    <h4 class="pay-table-text value-width">R$ {{bill.amount}}</h4>
                    <div class="link-width">
                        <a class="pay-link" target="_blank" :href="bill.chargeUrl">Boleto</a>
                    </div>
                    <div class="input-width">
                        <div class="input-containear ">
                            <Money v-bind="money" v-model="bill.value" type="text" class="input-value"/>
                        </div>
                    </div>
                    
                    <div class="action-width">
                        <Button text="Dar baixa" :action="()=>pay(bill)"/>
                    </div>
                    
                </div>
                <h1 class="pay-empty" v-if="bills.length < 1 && !isLoading">
                    Nenhum boleto disponível.
                </h1>
            </div>
        </div>

        <cc-loader-full v-if="isLoading"/>
    </div>
</template>

<script>
import Button from '@/components/ui/buttons/StandardButton.vue'
import ClientService from '../../../services/v3/client.service';
import { Money } from "v-money";

export default {
    name: 'PayModal',
    props:['closeDialog', 'store'],
    components:{
        Button,
        Money
    },
    data(){
        return {
            money: {
				decimal: ',',
				thousands: '.',
				prefix: 'R$ ',
				precision: 2,
				masked: false
			},
            svc: new ClientService(),
            isLoading: true,
            bills: []
        }
    },
    created(){
        this.load()
    },
    methods:{
        load(refresh){
            this.svc.getBills(this.store.id).then(resp=>{
                this.bills = resp.data
                this.isLoading = false
                if (refresh && resp.data.length < 1) this.closeDialog()
            })
        },
        pay(bill){
            if (bill.value <= 0){
                const notification = {
                    type: 'error',
                    message: 'Insira um valor valido.'
                }
                this.$store.dispatch('notification/add', notification)
                return
            }
            this.isLoading = true
            this.svc.payBill({
                "type": "contractual",
                "notes": "o pagamento foi gerado manual por: "+ localStorage.getItem('user_name'),
                "amount": bill.amount,
                "account": bill.account,
                "company": bill.company,
                "dueDate": Date.now(),
                "chargeId": bill.chargeId, 
                "customer": bill.customer,
                "ISSAmount": 0,
                "billetUrl": null,
                "chargeUrl": null,
                "createdAt": Date.now(),
                "rawAmount": null,
                "paidAmount": bill.value,
                "paymentDate": Date.now(),
                "receiptCode": null,
                "currentAmount": bill.value,
                "receiptAmount": 0,
                "competenceDate": Date.now(),
                "discountAmount": 0,
                "hasISSRetention": false,
                "receivingMethod": null,
                "rawReceiptAmount": 0,
                "taxInvoiceNumber": null,
                "installmentNumber": 1,
                "billetDigitableLine": null,
                "totalDiscountAmount": 0,
                "paymentOperationDate": Date.now(),
                "paymentDiscountAmount": 0,
                "hasAutomaticSettlement": true,
                "totalInstallmentsAmount":1
            }).then(()=>{
                this.load()
                this.$store.dispatch('notification/add', {
                    type: 'success',
                    message: 'Boleto atualizado.'
                })
            }).catch(()=>{
                this.isLoading = false
                this.$store.dispatch('notification/add', {
                    type: 'error',
                    message: 'Desculpe, não foi possível dar baixa.'
                })
            })
        }
    }
}
</script>

<style scoped>
.pay-modal-mask {
  position: fixed;
  z-index: 3;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  transition: opacity 0.3s ease;
}

.pay-modal-container {
  background-color: white;
  min-width: 90vw;
  border-radius: 12px;
  padding-bottom: 5vh;
}
.pay-header-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px;
  border-radius: 10px 10px 0px 0px;
  background-color: #ff7110;
}

.pay-header-title {
  color: #ffffff;
  font-weight: 500;
  font-size: 32px;
}

.closebtn-icon {
  color: #ffffff;
  font-size: 30px;
  cursor: pointer;
}
.pay-body{
    height: 60vh;
    padding: 6vh 2vw;
}
.pay-table-row{
    display: flex;
    justify-content: space-between;
    margin-bottom: 3vh;
    align-items: center;
}
.input-containear{
  border: 1px solid #E5E5E5;
  box-sizing: border-box;
  border-radius: 8px;
  padding: 1.5vh 0.7vw;
  display: flex;
  width: 80%;
  height: 6vh;
}
.input-value{
  border: none;
  flex: 1;
  font-weight: 400;
  font-size: 1.2em;
  letter-spacing: 0.15px;
  color: #6e6e6e;
  width: 100%;
}
.input-value:focus{
  border: none;
  outline: none;
}
.pay-table-text{
    color: gray;
    font-size: 22px;
}
.pay-empty{
    text-align: center;
    color: lightgray;
    font-size: 4em;
    margin-top: 10vh;
}
.page-table-header {
	background: #FFFEFC;
	border: 0.5px solid #E5E5E5;
	font-weight: 400;
	color: #605F5F;
	display: flex;
	padding: 1em 0em;
    margin-bottom: 5vh;
}
.page-table-header-text {
	padding-left: 10px;
	font-weight: 600;
	font-size: 1.19em;
	color: #505050;
}
.id-width{
    width: 10%;
}
.date-width{
    flex: 1;
}
.value-width{
    width: 17%;
}
.input-width{
    width: 25%;
}
.action-width{
    width: 15%;
}
.link-width{
    width: 13%;
}
.pay-link{
    color: gray;
    font-size: 25px;
    text-decoration: underline;
}
.pay-link:hover{
    color: #ff7110;
}
</style>