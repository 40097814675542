<template>
    <div id="seller" class="col">
        <div>
            <ValidationObserver v-slot="{ handleSubmit }">
                <form action="" v-if="buyer" @submit.prevent="handleSubmit( save )">
                    <div class="user-form-input-container">
                        <div class="col">
                            <!-- <label>Nome:<span style="color:red !important">*</span></label> -->
                            <div class="input-title" title="Obrigatório">
                              <!-- <span style="color:red !important">*</span> -->
                              Nome
                              <span class="material-icons" style="color: #CB595B;">
                                info
                              </span>
                            </div>
                            <ValidationProvider rules="required" v-slot="v">
                              <div class="input-container">
                                <input type="text" v-model="buyer.name" class="input-value" placeholder="Digite o nome">
                              </div>
                              <!-- <cc-input type="text" v-model="buyer.name"></cc-input> -->
                              <span class="error">{{ v.errors[0] }}</span>
                            </ValidationProvider>
                        </div>
                        <div class="col">
                          <div class="input-title" title="Obrigatório">
                            Email
                            <span class="material-icons" style="color: #CB595B;">
                              info
                            </span>
                          </div>
                          <!-- <label>Email:<span style="color:red !important">*</span></label> -->
                          <ValidationProvider rules="required" v-slot="v">
                            <div class="input-container">
                              <input type="text" v-model="buyer.email" class="input-value" placeholder="Digite o email">
                            </div>
                                <!-- <cc-input type="text" v-model="buyer.email"></cc-input> -->
                            <span class="error">{{ v.errors[0] }}</span>
                          </ValidationProvider>
                        </div>
                    </div>

                    <div class="user-form-input-container">
                        <div class="col">
                            <!-- <label>Perfil:<span style="color:red !important">*</span></label> -->
                            <div class="input-title" title="Obrigatório" style="margin-bottom: 5px;">
                              Perfil
                              <span class="material-icons" style="color: #CB595B;">
                                info
                              </span>
                            </div>
                            <ValidationProvider rules="required" v-slot="v" name="prof">
                              <!-- <cc-select-v2 :tracker="'id'"
                                            :text="'name'" 
                                            v-model="buyer.profile"
                                            :options="profiles" /> -->
                              <StandardInput :placeholder="buyer.profile && buyer.profile.name" :action="setProfile" :type="'select'" :list="formatprofiles()"/>
                              <span class="error">{{ v.errors[0] }}</span>
                            </ValidationProvider>
                        </div>
                        <div class="col">
                            <!-- <label>Telefone:<span style="color:red !important">*</span></label> -->
                            <div class="input-title" title="Obrigatório">
                              Telefone
                              <span class="material-icons" style="color: #CB595B;">
                                info
                              </span>
                            </div>
                            <ValidationProvider rules="required" v-slot="v">
                                <!-- <cc-input type="text" v-mask="'(##) #.####-####'" v-model="buyer.phone"></cc-input> -->
                                <div class="input-container">
                                  <input type="text" v-mask="'(##) #.####-####'" v-model="buyer.phone" class="input-value" placeholder="Digite o telefone"/>
                                </div>
                                <span class="error">{{ v.errors[0] }}</span>
                            </ValidationProvider>
                        </div>
                    </div>
                    <div class="user-form-input-container" v-if="buyer.profile && buyer.profile.name != 'ADMINISTRADOR'">
                        <div class="col">
                            <!-- <label>Perfil Secundário:<span style="color:red !important">*</span></label> -->
                            <div class="input-title" title="Obrigatório" style="margin-bottom: 5px;">
                              Perfil Secundário
                              <span class="material-icons" style="color: #CB595B;">
                                info
                              </span>
                            </div>
                            <ValidationProvider rules="required" v-slot="v" name="prof">
                                <!-- <cc-select-v2
                                    :tracker="'value'"
                                    :text="'key'"
                                    v-model="buyer.secondary_profile"
                                    :options="secondary_profiles" /> -->
                                <StandardInput :placeholder="buyer.secondary_profile ? buyer.secondary_profile.key : ''" :action="setSecodaryProfile" :type="'select'" :list="formatSecondaryProfiles()"/>
                                <span class="error">{{ v.errors[0] }}</span>
                            </ValidationProvider>
                        </div>
                        <div class="col">
                            <!-- <label>Empresa:</label> -->
                            <div class="input-title" style="margin-bottom: 8px;">
                              Empresa
                            </div>
                            <ValidationProvider rules="profile:@prof" v-slot="v">
                                <!-- <cc-select-v2
                                    :tracker="'id'"
                                    :text="'name'"
                                    :placeholder="'Selecione uma empresa'"
                                    v-model="buyer.store"
                                    :options="stores"
                                    :disabled="buyer.profile && buyer.profile.pfl_descricao == 'ADMINISTRADOR'" /> -->
                                <StandardInput :placeholder="buyer.store ? buyer.store.name : ''" :action="setStore" :type="'select'" :list="formatStores()"/>  
                                <span class="error">{{ v.errors[0] }}</span>
                            </ValidationProvider>
                        </div>
                    </div>

                    <div class="user-form-input-container">
                        <div class="col">
                            <!-- <label>Login:<span style="color:red !important">*</span></label> -->
                            <div class="input-title">
                              Login
                              <span class="material-icons" title="Obrigatório" style="color: #CB595B;">
                                info
                              </span>
                            </div>
                            <ValidationProvider rules="required" v-slot="v">
                              <!-- <cc-input type="text" v-model="buyer.login"></cc-input> -->
                              <div class="input-container">
                                <input type="text" v-model="buyer.login" class="input-value" placeholder="Digite o login"/>
                              </div>
                              <span class="error">{{ v.errors[0] }}</span>
                            </ValidationProvider>
                        </div>
                        <div class="col">
                            <!-- <label>Senha:<span style="color:red !important">*</span></label> -->
                            <div class="input-title">
                              Senha
                              <span class="material-icons" title="Obrigatório" style="color: #CB595B;">
                                info
                              </span>
                            </div>
                            <ValidationProvider rules="required" v-slot="v">
                              <!-- <cc-input type="password" v-model="buyer.decrypted_password"></cc-input> -->
                              <div class="input-container">
                                <input type="password" v-model="buyer.decrypted_password" class="input-value" placeholder="Digite a senha"/>
                              </div>
                              <span class="error">{{ v.errors[0] }}</span>
                            </ValidationProvider>
                        </div>
                    </div>


                    <div class="user-form-input-container">
                      <div class="col">
                          <!-- <label>Login:<span style="color:red !important">*</span></label> -->
                          <div class="input-title">
                            Tipo integração
                            <span class="material-icons" title="Obrigatório" style="color: #CB595B;">
                              info
                            </span>
                          </div>
                          <ValidationProvider rules="required" v-slot="v">
                            <!-- <cc-input type="text" v-model="buyer.login"></cc-input> -->
                            <StandardInput :placeholder="getType(buyer.integracao)" :action="setType" :type="'select'" :list="[{text: 'Prime', value: 'PRIME'}, {text: 'Varejo facil', value: 'VAREJO'}, {text: 'Sem integração', value: null}]"/>  
                            <span class="error">{{ v.errors[0] }}</span>
                          </ValidationProvider>
                      </div>
                      <div class="col"></div>
                  </div>


                    <div class="user-form-input-container">
                      <div class="col col-2 d-flex align-content-center">
                        <label></label>
                        <!-- <cc-checkbox :checked="buyer.has_direct_purchase == 1"
                                     v-model="buyer.has_direct_purchase"
                                     :label="'Compra direta?'" /> -->
                        <div v-if="buyer.has_direct_purchase == 1">
                          <label class="products-page__checkbox-wrapper">
                            <span class="lastbuydate_title">Compra direta</span>
                            <input type="checkbox" v-model="buyer.has_direct_purchase">
                            <span class="checkmark"></span>
                          </label>
                        </div>
                        <div v-else>
                          <label class="products-page__checkbox-wrapper">
                            <span class="lastbuydate_title">Compra direta</span>
                            <input type="checkbox" v-model="buyer.has_direct_purchase">
                            <span class="checkmark"></span>
                          </label>
                        </div>
                      </div>
                    </div>
                    <button ref="send-seller" type="submit" style="display:none;"></button>
                </form>
            </ValidationObserver>
        </div>
    </div>
</template>

<script>

import { ValidationProvider, ValidationObserver } from 'vee-validate';
import StandardInput from '@/components/ui/inputs/StandardInputV2.vue'
import { loaderMixin } from '@/mixins/sweet-loader.mixin';
import BuyerService from "@/services/v3/clients/buyer.service";

export default {
    mixins: [ loaderMixin ],
    components: {
        ValidationProvider,
        ValidationObserver,
        StandardInput
    },
    props: [ "buyer", "profiles", "stores", "secondary_profiles" ],
    data() {
        return {
            svc: new BuyerService()
        }
    },
    methods: {
      getType (type) {
        if (type == 'V') return 'Varejo facil'
        else if (type == 'P') return 'Prime'
        else return '--'
      },
      setType (value) {
        console.log(value);
        this.buyer.tipo = value
      },
      setProfile(value) { 
        this.buyer.profile = value
        if (value.name == "ADMINISTRADOR"){
          this.buyer.store = undefined
          this.buyer.secondary_profile = undefined
          this.$forceUpdate()
        }
      },
      formatprofiles(){
        const resp = []
        this.profiles.forEach(element => resp.push({text: element.name, value: element}))
        return resp
      },
      setStore(value){ this.buyer.store = value },
      formatStores(){
        const resp = []
        this.stores.forEach(element => resp.push({text: element.name, value: element}))
        return resp
      },
      setSecodaryProfile(value){ this.buyer.secondary_profile = value },
      formatSecondaryProfiles(){
        const resp = []
        this.secondary_profiles.forEach(element => resp.push({text: element.key, value: element}));
        return resp
      },
        save() {
            // this.present_loader("Salvando vendedor...")
            // return this.svc.save(this.buyer).then(() => {
            //     this.present_info('Dados salvos com sucesso!')
            //     this.$emit('reload')
            // }).catch((err) => {
            //     if(err.response && err.response.data.errors) {
            //         this.$store.dispatch('notification/add', {
            //             type: 'error',
            //             message: err.response.data.errors && err.response.data.errors[0]
            //         })
            //     }
            //     this.dismiss_loader()
            // })
        }
    }
}
</script>

<style scoped>
.input-title {
  font-weight: 400;
  font-size: 20px;
  line-height: 24px;
  letter-spacing: 0.15px;
  color: #898989;
}
.input-container {
  border: 1px solid #E5E5E5;
  box-sizing: border-box;
  border-radius: 8px;
  padding: 1.5vh 0.7vw;
  margin-top: 5px;
  display: flex;
}
.input-value {
  border: none;
  flex: 1;
  font-weight: 400;
  font-size: 14px;
  letter-spacing: 0.15px;
  color: #B3B3B3;
}
.input-value:focus {
  border: none;
  outline: none;
}
.lastbuydate_wrapper {
  display: flex;
  align-items: center;
  margin-top: 40px;
}
.products-page__checkbox-wrapper {
  display: flex;
}
/* The container */
.products-page__checkbox-wrapper {
  display: block;
  position: relative;
  padding-left: 35px;
  margin-bottom: 0px;
  cursor: pointer;
  font-size: 1.7em;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  color: #898989;
}

/* Hide the browser's default checkbox */
.products-page__checkbox-wrapper input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

/* Create a custom checkbox */
.checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 25px;
  width: 25px;
  background-color: #fff;
  border: 2.5px solid #ff7110;
  border-radius: 5px;
}

/* On mouse-over, add a grey background color */
.products-page__checkbox-wrapper:hover input ~ .checkmark {
  background-color: #ccc;
}

/* When the checkbox is checked, add a blue background */
.products-page__checkbox-wrapper input:checked ~ .checkmark {
  background-color: #ff7110;
  border: 1px solid #ff7110;
}

/* Create the checkmark/indicator (hidden when not checked) */
.checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

/* Show the checkmark when checked */
.products-page__checkbox-wrapper input:checked ~ .checkmark:after {
  display: block;
}

/* Style the checkmark/indicator */
.products-page__checkbox-wrapper .checkmark:after {
  left: 9px;
  top: 5px;
  width: 5px;
  height: 10px;
  border: solid #fff;
  border-width: 0 3px 3px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}
.user-form-input-container{
  display: flex;
}
@media only screen and (max-width: 650px) {
  .user-form-input-container{
    flex-direction: column;
  }
}
</style>
